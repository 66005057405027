import axios from "axios";

const runtimeConfig = useRuntimeConfig();

// if (runtimeConfig.public.mode !== "development") {
//   console.log = () => null;
// }

class APIResponse {
  data: object;
  status: number;
  error: string;

  constructor(data: object, status: number, error: string) {
    this.data = data != null ? data : {};
    this.status = status != null ? status : 0;
    this.error = error;
  }
}

interface Payload {
  api: string;
  data: object;
}

axios.defaults.baseURL = runtimeConfig.public.apiBase;

export function logout(b = true) {
  if (b) {
    if (!confirm("Are you sure you want to log out?")) {
      return;
    }
  }
  delete axios.defaults.headers.common["Authorization"];
  localStorage.removeItem("auth_token");
  runtimeConfig.public.isAuthenticated = false;
  navigateTo({ name: "logIn" });
}

export function login(token: string) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Token ${token}`;
    localStorage.setItem("auth_token", token);
    runtimeConfig.public.auth_token = token;
    runtimeConfig.public.isAuthenticated = true;
  } else {
    alert("Cannot log in, please contact administrator.");
  }
}

export async function setHeaders() {
  runtimeConfig.public.auth_token = localStorage.getItem("auth_token") || "";
  runtimeConfig.public.isAuthenticated = localStorage.getItem("auth_token")
    ? true
    : false;

  if (runtimeConfig.public.auth_token && runtimeConfig.public.isAuthenticated) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${runtimeConfig.public.auth_token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}

setHeaders();

export const get = async (api: string) => {
  runtimeConfig.public.isLoading = true;
  try {
    await setHeaders();
    const response = await axios
      .get(api)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx

          if (error.response.status === 401) {
            logout(false);
            return;
          }

          return error.response;
        }

        if (error.request) {
          // The request was made but no response was received.
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          return new APIResponse({}, 500, "Backend Service Unavailable");
        }

        // Something happened in setting up the request that triggered an Error
        return new APIResponse({}, 0, error.message);
      });
    console.log("get", api, response);
    runtimeConfig.public.isLoading = false;
    return response;
  } catch {}
  runtimeConfig.public.isLoading = false;
};

export const options = async (api: string) => {
  runtimeConfig.public.isLoading = true;
  try {
    setHeaders();
    const response = await axios
      .options(api)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx

          if (error.response.status === 401) {
            logout(false);
            return;
          }

          return error.response;
        }

        if (error.request) {
          // The request was made but no response was received.
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          return new APIResponse({}, 500, "Backend Service Unavailable");
        }

        // Something happened in setting up the request that triggered an Error
        return new APIResponse({}, 0, error.message);
      });

    console.log("option", api, response);
    runtimeConfig.public.isLoading = false;
    return response;
  } catch {}
  runtimeConfig.public.isLoading = false;
};

export const download = async (api: string) => {
  runtimeConfig.public.isLoading = true;
  try {
    setHeaders();

    const response = await axios
      .get(api, { responseType: "blob" })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            logout(false);
            return;
          }
          return error.response;
        }
        if (error.request) {
          return new APIResponse({}, 500, "Backend Service Unavailable");
        }
        return new APIResponse({}, 0, error.message);
      });

    console.log("download", api, response);
    runtimeConfig.public.isLoading = false;
    return response;
  } catch {}
  runtimeConfig.public.isLoading = false;
};

export const post = async (payload: Payload) => {
  runtimeConfig.public.isLoading = true;
  try {
    setHeaders();

    const response = await axios
      .post(payload.api, payload.data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx

          if (error.response.status === 401) {
            logout(false);
            return;
          }

          return error.response;
        }

        if (error.request) {
          // The request was made but no response was received.
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          return new APIResponse({}, 500, "Backend Service Unavailable");
        }

        // Something happened in setting up the request that triggered an Error
        return new APIResponse({}, 0, error.message);
      });

    console.log("post", payload, response);
    runtimeConfig.public.isLoading = false;
    return response;
  } catch {}
  runtimeConfig.public.isLoading = false;
};

export const put = async (payload: Payload) => {
  runtimeConfig.public.isLoading = true;
  try {
    setHeaders();

    const response = await axios
      .put(payload.api, payload.data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx

          if (error.response.status === 401) {
            logout(false);
            return;
          }

          return error.response;
        }

        if (error.request) {
          // The request was made but no response was received.
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          return new APIResponse({}, 500, "Backend Service Unavailable");
        }

        // Something happened in setting up the request that triggered an Error
        return new APIResponse({}, 0, error.message);
      });

    console.log("put", payload, response);
    runtimeConfig.public.isLoading = false;
    return response;
  } catch {}
  runtimeConfig.public.isLoading = false;
};

export const patchReq = async (payload: Payload) => {
  runtimeConfig.public.isLoading = true;
  try {
    setHeaders();

    const response = await axios
      .patch(payload.api, payload.data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx

          if (error.response.status === 401) {
            logout(false);
            return;
          }

          return error.response;
        }

        if (error.request) {
          // The request was made but no response was received.
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          return new APIResponse({}, 500, "Backend Service Unavailable");
        }

        // Something happened in setting up the request that triggered an Error
        return new APIResponse({}, 0, error.message);
      });

    console.log("patch", payload, response);
    runtimeConfig.public.isLoading = false;
    return response;
  } catch {}
  runtimeConfig.public.isLoading = false;
};

export const deleteReq = async (api: string) => {
  runtimeConfig.public.isLoading = true;
  try {
    setHeaders();

    const response = await axios
      .delete(api)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // This error handling is recommended by axios: https://axios-http.com/docs/handling_errors
        if (error.response) {
          // The request was made and the server responded with a status code that falls out of the range of 2xx

          if (error.response.status === 401) {
            logout(false);
            return;
          }

          return error.response;
        }

        if (error.request) {
          // The request was made but no response was received.
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          return new APIResponse({}, 500, "Backend Service Unavailable");
        }

        // Something happened in setting up the request that triggered an Error
        return new APIResponse({}, 0, error.message);
      });

    console.log("delete", api, response);
    runtimeConfig.public.isLoading = false;
    return response;
  } catch {}
  runtimeConfig.public.isLoading = false;
};
